import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Slide, } from '@mui/material';
import { forwardRef, useState } from 'react';
import { Link } from 'react-router-dom';
import info from '../../assets/images/vehicles/info.png';
import { useAuthContext } from '../../auth/useAuthContext';
import Image from '../../components/image';
import { useSettingsContext } from '../../components/settings';
import { HEADER, NAV } from '../../config-global';
import useResponsive from '../../hooks/useResponsive';
import { useLocales } from '../../locales';
import { PATH_DASHBOARD } from '../../routes/paths';
// ----------------------------------------------------------------------
const SPACING = 8;
const Transition = forwardRef((props, ref) => (_jsx(Slide, { direction: "up", ref: ref, ...props })));
export default function Main({ children, sx, ...other }) {
    const { themeLayout } = useSettingsContext();
    const { translate } = useLocales();
    const isNavHorizontal = themeLayout === 'horizontal';
    const isNavMini = themeLayout === 'mini';
    const isDesktop = useResponsive('up', 'lg');
    const { user } = useAuthContext();
    const [open, setOpen] = useState(user?.agenciesUser?.length === 0 && user?.role?.code?.toUpperCase() === 'ADMIN');
    const handleClose = () => {
        setOpen(false);
    };
    if (isNavHorizontal) {
        return (_jsx(Box, { component: "main", sx: {
                pt: `${HEADER.H_MOBILE + SPACING}px`,
                pb: `${HEADER.H_MOBILE + SPACING}px`,
                ...(isDesktop && {
                    px: 2,
                    pt: `${HEADER.H_DASHBOARD_DESKTOP + 80}px`,
                    pb: `${HEADER.H_DASHBOARD_DESKTOP + SPACING}px`,
                }),
            }, children: children }));
    }
    return (_jsx(_Fragment, { children: _jsxs(Box, { component: "main", sx: {
                flexGrow: 1,
                py: `${HEADER.H_MOBILE + SPACING}px`,
                ...(isDesktop && {
                    px: 2,
                    py: `${HEADER.H_DASHBOARD_DESKTOP + SPACING}px`,
                    width: `calc(100% - ${NAV.W_DASHBOARD}px)`,
                    ...(isNavMini && {
                        width: `calc(100% - ${NAV.W_DASHBOARD_MINI}px)`,
                    }),
                }),
                ...sx,
            }, ...other, children: [children, open && (_jsxs(Dialog, { open: open, TransitionComponent: Transition, keepMounted: true, onClose: handleClose, children: [_jsx(DialogTitle, { children: _jsx(Image, { src: info, sx: {
                                    borderRadius: 1.5,
                                    cursor: 'pointer',
                                    width: '70px',
                                    height: '70px',
                                    objectFit: 'cover',
                                } }) }), _jsx(DialogContent, { children: _jsxs(DialogContentText, { id: "alert-dialog-slide-description", children: [`${translate('To set up a new agency, kindly proceed by clicking on the following link')}`, "\u00A0", _jsx(Link, { to: PATH_DASHBOARD.settings.newAgency, onClick: () => setOpen(false), style: { color: '#00AB55', fontWeight: 'bold' }, children: `${translate('New Agency')}` }), "\u00A0", ' ', `${translate('This will guide you through the process of creating a new agency for your account.')}`] }) }), _jsx(DialogActions, { children: _jsx(Button, { variant: "contained", color: "error", onClick: () => {
                                    setOpen(false);
                                }, children: `${translate('Close')}` }) })] }))] }) }));
}
